// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';

// API
// ----------------------------------------------------------------------
// export const APP_URL = window.location.origin;



// export const API_URL = 'https://royal-backend.360-digital.net/api/';
// export const MEDIA_URL = 'https://royal-backend.360-digital.net';

export const API_URL = 'https://backend.royal234.net/api/';
export const MEDIA_URL = 'https://backend.royal234.net/';


// LAYOUT
// ----------------------------------------------------------------------
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'MM',
    value: 'mm',
  },
];

export const defaultLang = allLangs[0]; // English
