const mm = {
  // Button
  Update: 'ပြောင်းမည်',
  SaveChange: 'ပြောင်းလဲမည်',
  AddImage: 'ပုံထည့်မည်',
  AddAvatar: 'ရုပ်ပုံထည့်မည်',
  AddNewContactChannel: 'ဆက်သွယ်ရန် ချန်နယ် အသစ်ထည့်မည်',
  Confirm: 'အတည်ပြုမည်',
  Cancel: 'မပြောင်းတော့ပါ',
  CancelAdding: 'မထည့်ပါ',
  CancelCreating: 'မပြုလုပ်တော့ပါ',
  CancelSending: 'မပို့တော့ပါ',
  Calculate: 'တွက်ချက်မည်',
  AddLimitedNumber: 'ကန့်သတ်ထားသော နံပါတ်ကို ထည့်မည်',
  Add: 'ထည့်မည်',
  Save: 'မှတ်ထားမည်',
  Delete: 'ဖျက်မည်',
  CreateNewTimePeriod: 'ထီထွက်ချိန် အသစ် ပြုလုပ်မည်',
  Create: 'ပြုလုပ်မည်',
  CreateNewPeriod: 'ထီထွက်ချိန် အသစ် ပြုလုပ်မည်',
  EditThaiLotteryDetail: 'Thai Lottery ကို အသေးစိတ်ပြုပြင်မည်',
  AddNewTicket: 'ထီလက်မှတ် အသစ်ထည့်မည်',
  Edit: 'ပြုပြင်မည်',
  EditSingaporeLotteryDetail: 'Singapore Sweep ကို အသေးစိတ်ပြုပြင်မည်',
  AddLuckyNumber: 'ပေါက်ဂဏန်း ထည့်မည်',
  CreateNewAgent: 'အေးဂျင့်အသစ် ပြုလုပ်မည်',
  UpdatePassword: 'စကားဝှက် ကို ပြောင်းမည်',
  EditAgentDetail: 'အေးဂျင့် အချက်အလက် အသေးစိတ် ကို ပြုပြင်မည်',
  CreateNewCommission: 'ကော်မရှင်အသစ် ပြုလုပ်မည်',
  EditCommission: 'ကော်မရှင် ပြုပြင်မည်',
  NoCommissionBadge: 'ကော်မရှင် မရှိပါ',
  Send: 'ပို့မည်',
  AddNewPaymentMethod: 'ငွေပေးချေမှု စနစ်အသစ် ထည့်မည်',
  AddContactChannel: 'ဆက်သွယ်ရန် ချန်နယ် ထည့်ခြင်း',
  AddPrize: 'ဆုထည့်မည်',

  // Common
  Search: 'ရှာဖွေမည်',
  Action: 'လုပ်ဆောင်ချက်',
  Active: 'Active',
  Closed: 'Closed',
  Online: 'Online',
  Offline: 'Offline',
  Pending: 'လုပ်ဆောင်နေဆဲ',
  Accepted: 'လက်ခံပြီး',
  Rejected: 'ငြင်းပယ်ပြီး',
  PayToAdmin: 'အက်မင် ထံမှ လက်ခံခြင်း',
  PayFromAdmin: 'အက်မင် ထံမှ ပေးချေမှု',
  deposit: 'ငွေဖြည့်ခြင်း',
  withdraw: 'ငွေထုတ်ခြင်း',
  all: 'အားလုံး',
  NoData: 'xxx အတွက် အချက်အလက်များမရှိပါ',
  ScreenShot: 'ScreenShot',
  AddPaymentScreenshot: 'ScreenShot ထည့်ပါ',
  //  List
  Date: 'ရက်စွဲ',
  Time: 'အချိန်',
  PlayerName: 'ထိုးသား အမည်',
  AgentName: 'အေးဂျင့် အမည်',
  BetNumberList: 'ထိုးခဲ့သော နံပါတ်စာရင်း',
  BetTime: 'ထိုးခဲ့သော အချိန်',
  BetNumber: 'ထိုးခဲ့သော နံပါတ်',
  BetAmount: 'ထိုးခဲ့သော ပမာဏ',
  WinNumber: 'ပေါက်ဂဏန်း',
  WinAmount: 'ပေါက်ငွေ',
  PrizeInBaht: 'ဆုကြေး (ဘတ်)',
  WinAmountMMK: 'ပေါက်ငွေပမာဏ (ကျပ်)',
  PrizeInDollar: 'ဆုကြေး (စင်ကာပူ ဒေါ်လာ)',
  AgentCode: 'အေးဂျင့် ကုဒ်',
  PlayerCount: 'ထိုးသား အရေအတွက်',
  TotalCommission: 'စုစုပေါင်း ကော်မရှင်',
  LastCheckout: 'နောက်ဆုံး ငွေစာရင်းရှင်းတမ်း',
  FlowWalletAmount: 'ငွေစာရင်းပမာဏ',
  DateAndTime: 'ရက်စွဲ နှင့် အချိန်',
  CheckoutType: 'ငွေစာရင်း အမျိုးအစား',
  CheckoutAmount: 'ငွေစာရင်း ပမာဏ',
  PaymentType: 'ငွေပေးချေမှု အမျိုးအစား',
  Type: 'အမျိုးအစား',
  PlayersTotalDeposit: 'ထိုးသား၏ စုစုပေါင်း ငွေဖြည့်သွင်းမှု',
  PlayersTotalWithdraw: 'ထိုးသား၏ စုစုပေါင်း ငွေထုတ်ယူမှု',
  RequestCheckout: 'ငွေစာရင်းရှင်းမည်',
  TransactionType: 'ငွေစာရင်း အမျိုးအစား',
  UserType: 'အသုံးပြုသူ အမျိုးအစား',
  StartDateToEndDate: 'Start Date To End Date',
  ExportCSVFile: 'CSV ဖိုင်ထုတ်မည်',
  UserName: 'အကောင့် အမည်',
  AccountType: 'အကောင့် အမျိုးအစား',
  to_agent: 'အေးဂျင့် သို့',
  to_admin: 'အက်မင် သို့',
  // Modal
  Number: 'နံပါတ်',
  Amount: 'ပမာဏ',
  Name: 'အမည်',
  Password: 'စကားဝှက်',
  ConfrimPassword: 'စကားဝှက် အတည်ပြုမည်',
  State: 'ပြည်နယ်',
  City: 'မြို့နယ်',
  AccountPhoneNumber: 'အကောင့်ပြုလုပ်ထား‌‌သော ဖုန်းနံပါတ်',
  ContactPhoneNumberOne: 'ဆက်သွယ်ရန် ဖုန်းနံပါတ် ၁',
  ContactPhoneNumberTwo: 'ဆက်သွယ်ရန် ဖုန်းနံပါတ် ၂',
  DepositAmount: 'ဖြည့်ငွေပမာဏ',
  PromoAmount: 'ပလိုမိုးရှင်း ပမာဏ',
  PhoneNumber: 'ဖုန်းနံပါတ်',
  Period: 'ထီထွက်ချိန်',
  EnterRequestAmount: 'တောင်းဆိုသည့်ပမာဏ ကိုဖြည့်ပါ',
  PaymentName: 'ငွေပေးချေစနစ်၏ အမည်',
  AccountNumber: 'အကောင့်နံပါတ်',
  TimeDuration: 'ကြာချိန်',
  AccountName: 'အကောင့်အမည်',
  TransactionID: 'လုပ်ဆောင်မှု အမှတ်စဥ်',
  Note: 'မှတ်ချက်',
  EnterLuckyNumber: 'ပေါက်ဂဏန်း ထည့်ပါ',
  EnterBetCompensation: 'လျော်ကြေး (အဆပေါင်း) ထည့်ပါ',
  EnterLimitedAmount: 'ကန့်သတ်ပမာဏ ထည့်ပါ',
  EnterBeforeCloseTime: 'ထီပိတ်ချိန် မတိုင်ခင် ထည့်ပါ',
  EnterTime: 'ထီပိတ်ချိန်ထည့်ပါ',
  EnterDefaultNumberLimitAmount: 'ပုံသေကန့်သတ်ပမာဏ ထည့်ပါ',
  AddAmount: 'ပမာဏ ထည့်ရန်',
  EnterAmount: 'ပမာဏ ထည့်ပါ',
  // General
  General: 'အထွေထွေ',
  Dashboard: 'ပင်မ',
  Setting: 'ဆက်တင်',
  // Management
  Management: 'စီမံခြင်း',
  TwoDManagement: '2D စီမံခြင်း',
  OddCard: 'ထီဂဏန်း',
  TimePeriod: 'ထီထွက်ချိန်များ',
  SalesList: '‌အရောင်းစာရင်း',
  WinnerList: 'ထီပေါက်သူ စာရင်း',
  ThreeDManagement: '3D စီမံခြင်း',
  ThaiLottery: 'Thai Lottery',
  TicketList: 'ထီလက်မှတ် စာရင်း',
  SingaporeSweeep: 'Singapore Sweep',
  // User Management
  UserManagement: 'အသုံးပြုသူ စီမံခြင်း',
  Agent: 'အေးဂျင့်',
  AgentList: 'အေးဂျင့် စာရင်း',
  AgentCommission: 'အေးဂျင့် ကော်မရှင်',
  Player: 'ထိုးသား',
  // Flow Wallet Management
  FlowWalletManagement: 'ငွေစာရင်း စီမံခြင်း',
  FlowWallet: 'ငွေစာရင်း စီးဆင်းမှု',
  AgentRequest: 'အေးဂျင့်၏ ငွေတောင်းခံမှု',
  PlayerRequest: 'ထိုးသား၏ ငွေတောင်းခံမှု',
  PaymentSettings: 'ငွေပေးချေမှု စီမံခြင်း',
  TransactionHistory: 'ငွေကြေးလုပ်ဆောင်မှု မှတ်တမ်း',
  History: 'မှတ်တမ်း',
  // Admin Pop up
  Home: 'ပင်မ',
  Profile: 'ပရိုဖိုင်',
  Logout: 'ထွက်မည်',
  // Admin Profile Page
  Admin: 'အက်မင်',
  AccountSetting: 'အကောင့် ဆက်တင်',
  AdminFirstName: 'အက်မင် ရှေ့အမည်',
  AdminLastName: 'အက်မင် နောက်အမည်',
  AdminUserName: 'အက်မင် အကောင့်အမည်',
  ContactPhoneNumber: 'ဆက်သွယ်ရန် ဖုန်းနံပါတ်',
  ChangeAvatar: 'ရုပ်ပုံ ပြောင်းလဲမည်',
  OldPassword: 'အရင် စကားဝှက်ထည့်ပါ',
  NewPassword: 'စကားဝှက် အသစ်ထည့်ပါ',
  ConfirmPassword: 'စကားဝှက်အား အတည်ပြုမည်',
  ChangePassword: 'စကားဝှက်အား ပြောင်းလဲမည်',
  UpdateAdminProfileSuccessful: 'အက်မင် ပရိုဖိုင်အား ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  UpdateAvatarSuccessful: 'ရုပ်ပုံပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  UpdatePasswordSuccessful: 'စကားဝှက်ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  // Dashboard Page
  TotalSale: 'စုစုပေါင်း အရောင်း ပမာဏ',
  TotalDeposit: 'စုစုပေါင်း ငွေဖြည့်သွင်းမှု',
  TotalWithdraw: 'စုစုပေါင်း ငွေထုတ်ယူမှု',
  TotalCheckout: 'စုစုပေါင်း ငွေစာရင်းရှင်းမှု',
  LatestCheckoutRequest: 'နောက်ဆုံး ငွေစာရင်းရှင်းတမ်း',
  LatestTransactionHistory: 'နောက်ဆုံး ငွေကြေးလုပ်ဆောင်မှု မှတ်တမ်း',
  // General Setting Page
  GeneralSetting: 'အထွေထွေ စီမံခြင်း',
  BannerImage: 'ကြော်ငြာပုံများ',
  Avatar: 'ရုပ်ပုံ',
  ContactChannel: 'ဆက်သွယ်ရန် ချန်နယ်များ',
  ChannelType: 'ချန်နယ် အမျိုးအစား',
  ChannelName: 'ချန်နယ် အမည်',
  // 2D Odd Card Page
  TwoDOddCard: '2D ထီဂဏန်း',
  OddCard: 'ထီဂဏန်း',
  UpdateOddNumeberLimitAmountSuccessful: 'ထီဂဏန်း ၏ကန့်သတ်ပမာဏ ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  EditOddNumber: 'ထီဂဏန်းအား ပြောင်းလဲခြင်း',
  // 2D Time Period
  TwoDTimePeriod: '2D ထီထွက်ချိန်များ',
  TodayisOffDay: 'ယနေ့ ထီမဖွင့်ပါ',
  // 2D Time Period Detail
  EditTimePeriod: 'ထီထွက်ချိန် ပြောင်းလဲမည်',
  LuckyNumber: 'ပေါက်ဂဏန်း',
  SalesAmount: 'ရောင်းရငွေ ပမာဏ',
  BetCompensation: 'လျော်ကြေး (အဆပေါင်း)',
  AllNumberLimitedAmount: 'ဂဏန်း အားလုံးအတွက် ကန့်သတ်ထားသော ပမာဏ ',
  CloseTime: 'ထီပိတ်ချိန်',
  Status: 'အခြေအနေ',
  EditTimePeriodDetail: 'ထီထွက်ချိန် အသေးစိတ် အချက်အလက်များကို ပြုပြင်မည်',
  LimitedNumber: 'ကန့်သတ်ထားသော နံပါတ်များ',
  EnterTheNumber: 'နံပါတ် ထည့်ပါ',
  EnterLimitAmount: 'ကန့်သတ်ပမာဏ ထည့်ပါ',
  SaleList: 'အရောင်းစာရင်း',
  CalculateLuckyNumberSuccessful: 'ပေါက်ဂဏန်း တွက်ချက်ခြင်း အောင်မြင်ပါသည်။',
  UpdateTimePeriodSuccessful: 'ထီထွက်ချိန်ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  DeleteLimitedNumberSuccessful: 'ထီဂဏန်း၏ပမာဏ ကန့်သတ်ထားခြင်းအား ဖျက်သိမ်းခြင်း အောင်မြင်ပါသည်။',
  AddLimitedNumberSuccessful: 'ထီဂဏန်း၏ပမာဏ ကန့်သတ်ထားခြင်း အောင်မြင်ပါသည်။',
  UpdateLimitedNumberAmountSuccessful: 'ကန့်သတ်ထားသော ထီဂဏန်းပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  // 2D Sales List Page
  TwoDSalesList: '2D ‌အရောင်းစာရင်း',
  // 2D Winner List Page
  TwoDWinnerList: '2D ထီပေါက်သူ စာရင်း',
  // 2D Setting
  TwoDSetting: '2D စီမံခြင်း',
  DefaultNumberLimitAmount: 'ပုံသေကန့်သတ်ပမာဏ',
  BeforeCloseTime: 'ထီပိတ်ချိန် မတိုင်ခင်',
  DefaultNumbersLimitAmount: 'ပုံသေကန့်သတ်ပမာဏ',
  CreateNewSettingSuccessful: 'ဆက်တင် အသစ်ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  UpdateSettingSuccessful: 'ဆက်တင် ပြောင်းလဲခြင်း အောင်မြင်ပါသည်',
  DeleteSettingSuccessful: 'ဆက်တင် ဖျက်သိမ်းခြင်း အောင်မြင်ပါသည်',
  UpdateOddNumberLimitAmountSuccessful: 'ဂဏန်း၏ ကန့်သတ်ပမာဏ ပြုပြင်ခြင်း အောင်မြင်ပါသည်',
  // 2D Off Day
  OffDays: 'ပိတ်ရက်များ',
  OffDay: 'ပိတ်ရက်',
  Description: 'အကြောင်းအရာ',
  AddOffDay: 'ပိတ်ရက်ထည့်ရန်',
  EnterDescription: 'အကြောင်းအရာ ထည့်ပါ',
  SelectOffDay: 'ပိတ်ရက်ရွေးပါ',
  CreateNewOffDaySuccessful: 'ပိတ်ရက် ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  EditOffDaySuccessful: 'ပိတ်ရက် ပြောင်းလဲခြင်း အောင်မြင်ပါသည်',
  DeleteOffDaySuccessful: 'ပိတ်ရက် ဖျက်သိမ်းခြင်း အောင်မြင်ပါသည်',
  EditOffDay: 'ပိတ်ရက်ပြုပြင်ခြင်း',
  // 3D Odd Card
  ThreeDOddCard: '3D ထီဂဏန်း',
  LimitAmount: 'ကန့်သတ်ပမာဏ',
  FindByDate: 'ရက်စွဲဖြင့် ရှာမည်',
  FindByTimePeriod: 'ထီထွက်ချိန်ဖြင့် ရှာမည်',
  // 3D Create Period
  ThreeDCreateTimePeriod: '3D ထီထွက်ချိန် အသစ် ပြုလုပ်မည်',
  CreateTimePeriod: 'ထီထွက်ချိန် ပြုလုပ်မည်',
  SelectDate: 'ရက်စွဲ ရွေးပါ',
  ThreeDTimePeriod: '3D ထီထွက်ချိန်',
  SelectBetCompensation: 'လျော်ကြေး (အဆပေါင်း) ရွေးပါ',
  SelectAllNumberLimitAmount: 'ဂဏန်း အားလုံးအတွက် ကန့်သတ်ပမာဏ ရွေးပါ ',
  SelectCloseTime: 'ထီပိတ်ချိန် ရွေးပါ',
  NumberLimitAmount: 'ဂဏန်း၏ ကန့်သတ်ပမာဏ',
  EnterAllNumberLimitedAmount: 'ဂဏန်းအားလုံး၏ ကန့်သတ်ပမာဏ ထည့်ပါ',
  EditLimitAmount: 'ကန့်သတ်ပမာဏ ကို ပြုပြင်မည်',
  // 3D Sales List
  ThreeDSalesList: '3D ‌အရောင်းစာရင်း',
  // 3D Winner List
  ThreeDWinnerList: '3D ထီပေါက်သူ စာရင်း',
  // 3D Setting
  ThreeDSetting: '3D စီမံခြင်း',
  SideBetCompensation: 'ဘေးတိုက်ဆုလျော်ကြေး (အဆပေါင်း)',
  SelectSideBetCompensation: 'ဘေးတိုက်ဆုလျော်ကြေး (အဆပေါင်း) ရွေးပါ',
  // Thai Lottery Ticket List
  ThaiLotteryTicketList: 'ထိုင်း ထီလက်မှတ် စာရင်း',
  ThaiLottery: 'Thai Lottery',
  TicketList: 'ထီလက်မှတ် စာရင်း',
  Left: 'ကျန်သော ပမဏ',
  // Thai Lottery Time Period
  ThaiLotteryTimePeriod: 'ထိုင်း ထီထွက်ချိန်များ',
  Sales: 'ရောင်းအား',
  DrawNo: 'ထီထွက်မည့် အကြိမ်‌ရေ',
  CreateNewPeriodSuccessful: 'ထီထွက်ချိန် အသစ်ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  EnterDrawNo: 'ထီထွက်မည့် အကြိမ်‌ရေ ထည့်ပါ',
  // Thai Lottery Time Period Detail
  TotalSalesAmount: 'စုစုပေါင်း အရောင်းပမာဏ',
  CreateNewTicket: 'ထီလက်မှတ် အသစ်ပြုလုပ်မည်',
  TicketNumber: 'ထီနံပါတ်',
  EditLuckyNumber: 'ပေါက်ဂဏန်း ပြုပြင်မည်',
  DrawDate: 'ထီထွက်မည့်နေ့',
  CreateNewTicketSuccessful: 'ထီလက်မှတ် အသစ်ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  AddLuckyNumberSuccessful: 'ပေါက်ဂဏန်း ထည့်သွင်းခြင်း အောင်မြင်ပါသည်။',
  UpdateLuckyNumberSuccessful: 'ပေါက်ဂဏန်း ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  UpdateTicketSuccessful: 'ထီလက်မှတ် ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  EnterTicketNumber: 'ထီနံပါတ် ထည့်ပါ',
  EnterTicketQuantity: 'ထီအရေအတွက် ထည့်ပါ',
  EnterTicketPrice: 'ထီလက်မှတ်တန်ဖိုး ထည့်ပါ',
  EnterCloseTime: 'ထီပိတ်ချိန် ထည့်ပါ',
  WinningTicketNumber: 'အနိုင်ရရှိသည့် ထီနံပါတ်',
  TicketImage: 'ထီလက်မှတ်ပုံ',
  EnterTicketImage: 'ထီလက်မှတ်ပုံ ထည့်ပါ',
  // Thai Sales List
  ThaiLotterySalesList: 'ထိုင်းထီ အရောင်းစာရင်း',
  // Thai Winner List
  ThaiLotteryWinnerList: 'ထိုင်းထီပေါက်သူ စာရင်း ',
  // Thai Settinig
  GlobalSetting: 'အလုံးစုံစီမံမှု',
  TicketPrice: 'ထီလက်မှတ်တန်ဖိုး',
  minute: 'မိနစ်',
  PrizeTitles: 'ထီဆုကြေးများ',
  PrizeTitle: 'ထီဆုကြေး',
  PrizeAmount: 'ဆုကြေး ပမာဏ',
  PrizeQuantity: 'ဆုကြေး အရေအတွက်',
  PrizeType: 'ဆုကြေး အမျိုးအစား',
  Default: 'Default',
  FirstThreeDigit: 'ရှေ့ ၃ လုံးတိုက်',
  LastThreeDigit: 'နောက် ၃ လုံးတိုက်',
  LastTwoDigit: 'နောက် ၂ လုံးတိုက်',
  SidePrize: 'ပထမဆုရဲ့ ဘေးတိုက်ဆု',
  Baht: 'THB',
  CreateGlobalSettingSuccessful: 'Global Setting ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  UpdateGlobalSettingSuccessful: 'အထွေထွေစီမံမှု ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  CreateNewPrizeSuccessful: 'ဆုကြေးအသစ်ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  UpdatePrizeSuccessful: 'ဆုကြေး ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  DeletePrizeSuccessful: 'ဆုကြေးဖျက်သိမ်းခြင်း အောင်မြင်ပါသည်။',
  EnterPrizeTitle: 'ထီဆုကြေး ထည့်ပါ',
  EnterPrizeAmount: 'ဆုကြေး ပမာဏ ထည့်ပါ',
  EnterPrizeQuantity: 'ဆုကြေး အရေအတွက် ထည့်ပါ',
  UpdatePrize: 'ဆုကြေး ကိုပြုပြင်မည်',
  // Singapore Ticket List
  SingaporeTicketList: 'စင်ကာပူ ထီလက်မှတ် စာရင်း',
  // Singapore Time Period
  SingaporeTimePeriod: 'စင်ကာပူ ထီထွက်ချိန်',
  EditTicket: 'ထီလက်မှက် ကိုပြုပြင်မည်',
  TicketQuantity: 'ထီ အရေအတွက်',
  CreateTimePeriodSuccessful: 'ထီထွက်ချိန်အသစ် ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  // Singapore Winner List
  SingaporeLotterySalesList: 'စင်ကာပူထီ အရောင်းစာရင်း',
  SingaporeWinnerList: 'စင်ကာပူထီ ပေါက်သူများစာရင်း',
  // Agent
  AgentManagement: 'အေးဂျင့် စီမံခန့်ခွဲမှု',
  PasswordNotMatch: 'အတည်ပြုသော စကားဝှက်အား စကားဝှက်နှင့် တူအောင်ဖြည့်ပါ',
  CreateNewAgentSuccessful: 'အေးဂျင့်အသစ် ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  CreditBalance: 'Credit Balance',
  EnterCreditBalance: 'Enter Credit Balance',
  // Agent Detail
  AgentDetail: 'အေးဂျင့် အချက်အလက် အသေးစိတ်',
  JoinDate: 'စတင်သုံးသော နေ့',
  AgentsDetail: 'အေးဂျင့် ၏ အချက်အလက် အသေးစိတ်',
  Code: 'ကုဒ်',
  TotalCommissionAmount: 'စုစုပေါင်း ကော်မရှင်ပမာဏ',
  AgentsContact: 'အေးဂျင့် ၏ အချက်အလက်',
  CheckoutHistory: 'ငွေစာရင်း မှတ်တမ်း',
  PlayersDepositWidthdrawHistory: 'ထိုးသားများ ၏ ငွေသွင်း/ငွေထုတ် မှတ်တမ်း',
  AgentsPlayerList: 'အေးဂျင့်ပိုင်ဆိုင်သော ထိုးသားများ စာရင်း',
  EnterName: 'အမည် ထည့်ပါ',
  EnterPassword: 'စကားဝှက် ထည့်ပါ',
  EnterConfirmPassword: 'အတည်ပြုရန် စကားဝှက် ထည့်ပါ',
  EnterAccountPhoneNumber: 'အကောင့်ပြုလုပ်ခဲ့သော ဖုန်းနံပါတ်ထည့်ပါ',
  EnterContactPhoneNumberOne: 'ဆက်သွယ်ရန် ဖုန်းနံပါတ် ၁ ထည့်ပါ',
  EnterContactPhoneNumberTwo: 'ဆက်သွယ်ရန် ဖုန်းနံပါတ် ၂ ထည့်ပါ',
  CreateNewPassword: 'စကားဝှက် အသစ်ပြုလုပ်ပါ',
  ConfirmNewPassword: 'စကားဝှက် ကိုအတည်ပြုပါ',
  UpdateAgentSuccessful: 'အေးဂျင့်အကောင့်အား ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  UpdateAgentPasswordSuccessful: 'အေးဂျင့်စကားဝှက်​ ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  MaxWithdrawAmount: 'တကြိမ်လျှင် အများဆုံး ထုတ်ပေးနိုင်သော ပမာဏ',
  EnterMaxWithdrawAmount: 'တကြိမ်လျှင် အများဆုံး ထုတ်ပေးနိုင်သော ပမာဏ ထည့်ပါ',
  // agent Commission
  AgentCommissionList: 'အေးဂျင့် ကော်မရှင်စာရင်း',
  TargetAmount: 'သတ်မှတ်ထားသော ပမာဏ',
  CommissionPercent: 'ကော်မရှင် ရာခိုင်နှုန်း',
  EnterTargetAmount: 'သတ်မှတ်ထားသော ပမာဏ ရိုက်ထည့်ပါ',
  EnterCommissionPercent: 'ကော်မရှင် ရာခိုင်နှုန်း ရိုက်ထည့်ပါ',
  CreateNewCommissionSuccessful: 'ကော်မရှင်အသစ် ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  UpdateCommissionSuccessful: 'ကော်မရှင်ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  DeleteCommissionSuccessful: 'ကော်မရှင်ဖျက်သိမ်းခြင်း အောင်မြင်ပါသည်။',
  EnterPercentage: 'ရာခိုင်နှုန်း ထည့်ပါ',
  //   Player List
  PlayerList: 'ထိုးသား စာရင်း',
  TotalPlayer: 'ထိုးသား စုစုပေါင်း',
  ActivePlayer: 'လက်ရှိ ထိုးသား',
  //   Player Detail
  PlayerDetail: 'ထိုးသား အချက်အလက် အသေးစိတ်',
  WalletAmount: 'လက်ကျန်ငွေ',
  TotalBetAmount: 'စုစုပေါင်း ထိုးငွေပမာဏ',
  TotalWinAmount: 'စုစုပေါင်း ပေါက်ငွေပမာဏ',
  BetHistory: 'ထိုးခဲ့သောမှတ်တမ်း',
  DepositHistory: 'ငွေဖြည့်ခဲ့သောမှတ်တမ်း',
  WithdrawHistory: 'ငွေထုတ််ခဲ့သောမှတ်တမ်း',
  WinningAmount: 'အနိုင်ရသော ပမာဏ',
  BetDetails: 'ထိုးခဲ့သော အချက်အလက် အသေးစိတ်',
  DepositDetails: 'ငွေဖြည့်ခဲ့သော အချက်အလက်',
  WithdrawDetails: 'ငွေထုတ်ခဲ့သော အချက်အလက်',
  //   Payment Setting
  PaymentMethod: 'ငွေပေးချေမှု စနစ်',
  PaymentMethods: 'ငွေပေးချေမှု စနစ်များ',
  MyAccount: 'ကျွန်ုပ်၏ အကောင့်',
  AddNewPaymentMethodSuccessful: 'ငွေပေးချေမှုစနစ်အသစ် ထည့်သွင်းခြင်း အောင်မြင်ပါသည်။',
  DeletePaymentMethodSuccessful: 'ငွေပေးချေမှုစနစ် ဖျက်သိမ်းခြင်း အောင်မြင်ပါသည်။',
  CreateNewPaymentMethodSuccessful: 'ငွေပေးချေမှုစနစ်အသစ် ပြုလုပ်ခြင်း အောင်မြင်ပါသည်။',
  UpdatePaymentMethodSuccessful: 'ငွေပေးချေမှုစနစ် ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
  AddPaymentMethod: 'ငွေပေးချေမှုစနစ် ထည့်သွင်းခြင်း',
  // Request Checkout
  EnterEightDigit: 'လုပ်ဆောင်မှုအမှတ်စဥ်၏ နောက်ဆုံးဂဏန်း ၆လုံး ထည့်ပါ',
  LastEightDigit: 'လုပ်ဆောင်မှုအမှတ်စဥ် (နောက်ဆုံးဂဏန်း ၆လုံး)',
  AgentAccountName: 'အေးဂျင့် အကောင့်အမည်',
  AgentAccountNumber: 'အေးဂျင့် အကောင့်နံပါတ်',
  EnterNote: 'မှတ်ချက် ထည့်ပါ',
  CheckoutDetails: 'ငွေစာရင်း အသေးစိတ်',
  SendRequestSuccessful: 'ငွေစာရင်းရှင်းတမ်း ပေးပို့ခြင်း အောင်မြင်ပါသည်။',
  CheckoutRequestAccepted: 'ငွေစာရင်းရှင်းတမ်းအား လက်ခံသည်။',
  CheckoutRequestRejected: 'ငွေစာရင်းရှင်းတမ်းအား ငြင်းပယ်သည်။',
  AddPaymentScreenShot: 'ငွေပေးချေမှု ScreenShot ထည့်ပါ',
  subtract: 'နှုတ်မည်',
  // Add New
  Refund: 'ငွေပြန်အမ်းမည်',
  Reset: 'ပြန်လည်သတ်မှတ်မည်',
  RefundSalesListSuccessful: 'ငွေပြန်အမ်းခြင်း အောင်မြင်ပါသည်',
  ResetTimePeriodSuccessful: 'ထီထွက်ချိန် ပြန်လည်သတ်မှတ်ခြင်း အောင်မြင်ပါသည်',
  RefundSale: 'ငွေပြန်အမ်းမည်',
  RefundSaleSuccessful: 'ငွေပြန်အမ်းခြင်း အောင်မြင်ပါသည်',
  AlreadyRefunded: 'ငွေပြန်အမ်းခဲ့ပြီး ဖြစ်ပါသည်',
  AlreadyWinning: 'ထီပေါက်ခဲ့ပြီး ဖြစ်ပါသည်',
  AgentZeroError: 'တကြိမ်လျှင် အများဆုံး ထုတ်ပေးနိုင်သော ပမာဏသည် သုည မဖြစ်သင့်ပါ',
  MinTargetAmount: 'အနည်းဆုံး သတ်မှတ်ထားသော ပမာဏ',
  MaxTargetAmount: 'အများဆုံး သတ်မှတ်ထားသော ပမာဏ',
  EnterMinTargetAmount: 'အနည်းဆုံး သတ်မှတ်ထားသော ပမာဏ ထည့်ပါ',
  EnterMaxTargetAmount: 'အများဆုံး သတ်မှတ်ထားသော ပမာဏ ထည့်ပါ',
  // 4D
  FourD: '4D',
  PrizeCompensation: 'ဆုလျော်ကြေး (အဆပေါင်း)',
  EnterPrizeCompensation: 'ဆုလျော်ကြေး (အဆပေါင်း) ထည့်ပါ',
  FourDOddCard: '4D ထီဂဏန်း',
  FourDTimePeriod: '4D ထီထွက်ချိန်များ',
  FourDCreateTimePeriod: '4D ထီထွက်ချိန် အသစ် ပြုလုပ်မည်',
  ReleaseData: 'ထီထွက်မည့်နေ့',
  FourDManagement: '4D စီမံခြင်း',
  SortByNumber: 'နံပါတ်ဖြင့် စီမည်',
  SortBySale: 'ရောင်းအားဖြင့် စီမည်',
  FourDSalesList: '4D ‌အရောင်းစာရင်း',
  FourDWinnerList: '4D ထီပေါက်သူ စာရင်း',
  FourDSetting: '4D စီမံမှု',
  // extra add on
  thai: 'Thai Lottery',
  singapore: 'Singapore Sweep',
};

export default mm;
