// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  // Button
  Update: 'Update',
  SaveChange: 'Save Change',
  AddImage: 'Add Image',
  AddAvatar: 'Add Avatar',
  AddNewContactChannel: 'Add New Contact Channel',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  CancelAdding: 'Cancel',
  CancelCreating: 'Cancel',
  CancelSending: 'Cancel',
  Calculate: 'Calculate',
  AddLimitedNumber: 'Add Limited Number',
  Add: 'Add',
  Save: 'Save',
  Delete: 'Delete',
  CreateNewTimePeriod: 'Create New Time Period',
  Create: 'Create',
  CreateNewPeriod: 'Create New Period',
  EditThaiLotteryDetail: 'Edit Thai Lottery Detail',
  AddNewTicket: 'Add New Ticket',
  Edit: 'Edit',
  EditSingaporeLotteryDetail: 'Edit Singapore Lottery Detail',
  AddLuckyNumber: 'Add Lucky Number',
  CreateNewAgent: 'Create New Agent',
  UpdatePassword: 'Update Password',
  EditAgentDetail: 'Edit Agent Detail',
  CreateNewCommission: 'Create New Commission',
  EditCommission: 'Edit Commission',
  NoCommissionBadge: 'There is no Commission Badge',
  Send: 'Send',
  AddNewPaymentMethod: 'Add New Payment Method',
  AddContactChannel: 'Add Contact Channel',
  AddPrize: 'Add Prize',
  // Common
  Search: 'Search',
  Action: 'Action',
  Active: 'Acitve',
  Closed: 'Closed',
  Open: 'Open',
  Online: 'Online',
  Offline: 'Offline',
  Pending: 'Pending',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  PayToAdmin: 'Pay To Admin',
  PayFromAdmin: 'Pay From Admin',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  all: 'All',
  Bet: 'Bet',
  NoData: 'There is no Data for xxx....',
  ScreenShot: 'ScreenShot',
  AddPaymentScreenshot: 'Add Pyament Screenshot',
  //  List
  Date: 'Date',
  Time: 'Time',
  PlayerName: 'Player Name',
  AgentName: 'Agent Name',
  BetNumberList: 'Bet Number List',
  BetTime: 'Bet Time',
  BetNumber: 'Bet Number',
  BetAmount: 'Bet Amount',
  WinNumber: 'Win Number',
  WinAmount: 'Win Amount',
  PrizeInBaht: 'Prize (THB)',
  WinAmountMMK: 'Win Amount (MMK)',
  PrizeInDollar: 'Prize(SGD) ',
  AgentCode: 'Agent Code',
  PlayerCount: 'Player Count',
  TotalCommission: 'Total Commission',
  LastCheckout: 'Last Checkout',
  FlowWalletAmount: 'Flow Wallet Amount',
  DateAndTime: 'Date & Time',
  CheckoutType: 'Checkout Type',
  CheckoutAmount: 'Checkout Amount',
  PaymentType: 'Payment Type',
  Type: 'Type',
  PlayersTotalDeposit: "Player's Total Deposit",
  PlayersTotalWithdraw: "Player's Total Withdraw",
  RequestCheckout: 'Request Checkout',
  TransactionType: 'Transaction Type',
  UserType: 'User Type',
  StartDateToEndDate: 'Start Date To End Date',
  ExportCSVFile: 'Export CSV File',
  UserName: 'UserName',
  AccountType: 'Account Type',
  to_agent: 'To Agent',
  to_admin: 'To Admin',
  // Modal
  Number: 'Number',
  Amount: 'Amount',
  Name: 'Name',
  Password: 'Password',
  ConfrimPassword: 'Confirm Password',
  State: 'State',
  City: 'City',
  AccountPhoneNumber: 'Account Phone No.',
  ContactPhoneNumberOne: 'Contact Phone No. 1',
  ContactPhoneNumberTwo: 'Contact Phone No. 2',
  DepositAmount: 'Deposit Amount',
  PromoAmount: 'Promo Amount',
  PhoneNumber: 'Phone No.',
  Period: 'Period',
  EnterRequestAmount: 'Enter Request Amount',
  PaymentName: 'Payment Name',
  AccountNumber: 'Account Number',
  TimeDuration: 'Time Duration',
  AccountName: 'Account Name',
  TransactionID: 'Transaction ID',
  Note: 'Note',
  EnterLuckyNumber: 'Enter Lucky Number',
  EnterBetCompensation: 'Enter Bet Compensation',
  EnterLimitedAmount: 'Enter Limited Amount',
  EnterBeforeCloseTime: 'Enter Before Close Time',
  EnterTime: 'Enter Time',
  EnterDefaultNumberLimitAmount: 'Enter Default Number Limit Amount',
  AddAmount: 'Add Amount',
  EnterAmount: 'Enter Amount',
  // General
  General: 'General',
  Dashboard: 'Dashboard',
  Setting: 'Setting',
  // Management
  Management: 'Management',
  TwoDManagement: '2D Management',
  OddCard: 'Odd Card',
  TimePeriod: 'Time Period',
  SalesList: 'Sales List',
  WinnerList: 'Winner List',
  ThreeDManagement: '3D Management',
  ThaiLottery: 'Thai Lottery',
  TicketList: 'Ticket List',
  SingaporeSweep: 'Singapore Sweep',
  // User Management
  UserManagement: 'User Management',
  Agent: 'Agent',
  AgentList: 'Agent List',
  AgentCommission: 'Agent Commission',
  Player: 'Player',
  // Flow Wallet Management
  FlowWalletManagement: 'Flow Wallet Management',
  FlowWallet: 'Flow Wallet',
  AgentRequest: 'Agent Request',
  PlayerRequest: 'Player Request',
  PaymentSettings: 'Payment Settings',
  TransactionHistory: 'Transaction History',
  History: 'History',
  // Admin Pop up
  Home: 'Home',
  Profile: 'Profile',
  Settings: 'Settings',
  Logout: 'Logout',
  // Admin Profile Page
  Admin: 'Admin',
  AccountSetting: 'Account Setting',
  AdminFirstName: 'Admin First Name',
  AdminLastName: 'Admin Last Name',
  AdminUserName: 'Admin User Name',
  ContactPhoneNumber: 'Contact Phone No.',
  ChangeAvatar: 'Change Avatar',
  OldPassword: 'Enter Old Password',
  NewPassword: 'Enter New Password',
  ConfirmPassword: 'Enter Confirm Password',
  ChangePassword: 'Change Password',
  UpdateAdminProfileSuccessful: 'Update Admin Profile Successful!',
  UpdateAvatarSuccessful: 'Update Avatar Successful!',
  UpdatePasswordSuccessful: 'Update Password Successful!',
  // Dashboard Page
  TotalSale: 'Total Sale',
  TotalDeposit: 'Total Deposit',
  TotalWithdraw: 'Total Withdraw',
  TotalCheckout: 'Total Checkout',
  LatestCheckoutRequest: 'Latest Checkout Request',
  LatestTransactionHistory: 'Latest Transaction History',
  // General Setting Page
  GeneralSetting: 'General Setting',
  BannerImage: 'Banner Image',
  Avatar: 'Avatar',
  ContactChannel: 'Contact Channel',
  ChannelType: 'Channel Type',
  ChannelName: 'Channel Name',
  // 2D Odd Card Page
  TwoDOddCard: '2D Odd Card',
  OddCard: 'Odd Card',
  UpdateOddNumeberLimitAmountSuccessful: 'Update Odd Number Limit Amount Successful!',
  EditOddNumber: 'Edit Odd Number',
  // 2D Time Period
  TwoDTimePeriod: '2D Time Period',
  TodayisOffDay: 'Today is Off Day',
  // 2D Time Period Detail
  EditTimePeriod: 'Edit Time Period',
  LuckyNumber: 'Lucky Number',
  SalesAmount: 'Sales Amount',
  BetCompensation: 'Bet Compensation',
  AllNumberLimitedAmount: 'All Number Limited Amount',
  CloseTime: 'Close Time',
  Status: 'Status',
  EditTimePeriodDetail: 'Edit Time Period Detail',
  LimitedNumber: 'Limited Number',
  LimitedNumbers: 'Limited Numbers',
  SaleList: 'Sale List',
  EnterTheNumber: 'Enter The Number',
  EnterLimitAmount: 'Enter Limit Amount',
  CalculateLuckyNumberSuccessful: 'Calculate Lucky Number Successful!',
  UpdateTimePeriodSuccessful: 'Update Time Period Successful!',
  DeleteLimitedNumberSuccessful: 'Delete Limited Number Successful!',
  AddLimitedNumberSuccessful: 'Add Limited Number Successful!',
  UpdateLimitedNumberAmountSuccessful: 'Update Limited Number Amount Successful!',
  // 2D Sales List Page
  TwoDSalesList: '2D Sales List',
  // 2D Winner List Page
  TwoDWinnerList: '2D Winner List',
  // 2D Setting
  TwoDSetting: '2D Setting',
  DefaultNumberLimitAmount: 'Default Number Limit Amount',
  BeforeCloseTime: 'Before Close Time',
  DefaultNumbersLimitAmount: 'Default Numbers Limit AMount',
  CreateNewSettingSuccessful: 'Create New Setting Successful!',
  UpdateSettingSuccessful: 'Update Setting Successful!',
  DeleteSettingSuccessful: 'Delete Setting Successful!',
  UpdateOddNumberLimitAmountSuccessful: 'Update Odd Number Limit Amount Successful!',
  // 2D Off Day
  OffDays: 'Off Days',
  OffDay: 'Off Day',
  Description: 'Description',
  AddOffDay: 'Add Off Day',
  EnterDescription: 'Enter Description',
  SelectOffDay: 'Select Off Day',
  CreateNewOffDaySuccessful: 'Create New Off Day Successful!',
  EditOffDaySuccessful: 'Edit Off Day Successful!',
  DeleteOffDaySuccessful: 'Delete Off Day Successful!',
  EditOffDay: 'Edit Off Day',
  // 3D Odd Card
  ThreeDOddCard: '3D Odd Card',
  LimitAmount: 'Limit Amount',
  FindByDate: 'Find By Date',
  FindByTimePeriod: 'Find By TimePeriod',
  // 3D Create Period
  ThreeDCreateTimePeriod: '3D Create Time Period',
  CreateTimePeriod: 'Create Time Period',
  SelectDate: 'Select Date',
  ThreeDTimePeriod: '3D Time Period',
  SelectBetCompensation: 'Select Bet Compensation',
  SelectAllNumberLimitAmount: 'Select All Number Limit Amount',
  SelectCloseTime: 'Select Close Time',
  NumberLimitAmount: 'Number Limit Amount',
  EditLimitAmount: 'Edit Limit Amount',
  EnterAllNumberLimitedAmount: 'Enter All Number Limited Amount',

  // 3D Sales List
  ThreeDSalesList: '3D Sales List',
  // 3D Winner List
  ThreeDWinnerList: '3D Winner List',
  // 3D Setting
  ThreeDSetting: '3D Setting',
  SideBetCompensation: 'Side Bet Compensation',
  SelectSideBetCompensation: 'Select Side Bet Compensation',
  // Thai Lottery Ticket List
  ThaiLotteryTicketList: 'Thai Lottery Ticket List',
  ThaiLottery: 'Thai Lottery',
  TicketList: 'Ticket List',
  Left: 'Left',
  // Thai Lottery Time Period
  ThaiLotteryTimePeriod: 'Thai Lottery Time Period',
  Sales: 'Sales',
  DrawNo: 'Draw No.',
  CreateNewPeriodSuccessful: 'Create New Period Successful!',
  EnterDrawNo: 'Enter Draw No.',
  EnterBeforeCloseTime: 'Enter Before Close Time',
  // Thai Lottery Time Period Detail
  TotalSalesAmount: 'Total Sales Amount',
  CreateNewTicket: 'Create New Ticket',
  TicketNumber: 'Ticket Number',
  EditLuckyNumber: 'Edit Lucky Number',
  DrawDate: 'Draw Date',
  CreateNewTicketSuccessful: 'Create New Ticket Successful!',
  AddLuckyNumberSuccessful: 'Add Lucky Number Successful!',
  UpdateLuckyNumberSuccessful: 'Update Lucky Number Successful!',
  UpdateTicketSuccessful: 'Update Ticket Successful',
  EnterTicketNumber: 'Enter Ticket Number',
  EnterTicketQuantity: 'Enter Ticket Quantity',
  EnterTicketPrice: 'Enter Ticket Price',
  EnterCloseTime: 'Enter Close Time',
  WinningTicketNumber: 'Winning Ticket Number',
  TicketImage: 'Ticket Image',
  EnterTicketImage: 'Enter Ticket Image',
  // Thai Sales List
  ThaiLotterySalesList: 'Thai Lottery Sales List',
  // Thai Winner List
  ThaiLotteryWinnerList: 'Thai Lottery Winner List',
  // Thai Settinig
  GlobalSetting: 'Global Setting',
  TicketPrice: 'Ticket Price',
  minute: 'Minute',
  PrizeTitles: 'Prize Titles',
  PrizeTitle: 'Prize Title',
  PrizeAmount: 'Prize Amount',
  PrizeQuantity: 'Prize Quantity',
  PrizeType: 'Prize Type',
  Default: 'Default',
  FirstThreeDigit: 'First 3 Digit',
  LastThreeDigit: 'Last 3 Digit',
  LastTwoDigit: 'Last 2 Digit',
  SidePrize: 'Side Prize',
  THB: 'THB',
  CreateGlobalSettingSuccessful: 'Create Global Setting Successful!',
  UpdateGlobalSettingSuccessful: 'Update Global Setting Successful!',
  CreateNewPrizeSuccessful: 'Create New Prize Successful!',
  UpdatePrizeSuccessful: 'Update Prize Successful!',
  DeletePrizeSuccessful: 'Delete Prize Successful!',
  EnterPrizeTitle: 'Enter Prize Title',
  EnterPrizeAmount: 'Enter Prize Amount',
  EnterPrizeQuantity: 'Enter Prize Quantity',
  UpdatePrize: 'Update Prize',
  // Singapore Ticket List
  SingaporeLottery: 'Singapore Sweep',
  SingaporeTicketList: 'Singapore Sweep Ticket List',
  // Singapore Time Period
  SingaporeTimePeriod: 'Singapore Sweep Time Period',
  EditTicket: 'Edit Ticket',
  TicketQuantity: 'Ticket Quantity',
  CreateTimePeriodSuccessful: 'Create Time Period Successful!',
  // Singapore Winner List
  SingaporeLotterySalesList: 'Singapore Sweep Sales List',
  SingaporeWinnerList: 'Singapore Sweep Winner List',
  // Agent
  AgentManagement: 'Agent Management',
  PasswordNotMatch: 'The Confirm Password does not match the Password',
  CreateNewAgentSuccessful: 'Create New Agent Successful!',
  CreditBalance: 'Credit Balance',
  EnterCreditBalance: 'Enter Credit Balance',
  // Agent Detail
  AgentDetail: 'Agent Detail',
  JoinDate: 'Join Date',
  AgentsDetail: 'Agent Detail',
  Code: 'Code',
  TotalCommissionAmount: 'Total Commission Amount',
  AgentsContact: "Agent's Contact",
  CheckoutHistory: 'Checkout History',
  PlayersDepositWidthdrawHistory: "Player's Deposit/Withdraw History",
  AgentsPlayerList: "Agent's Player List",
  EnterName: 'Enter Name',
  EnterPassword: 'Enter Password',
  EnterConfirmPassword: 'Enter Confirm Password',
  EnterAccountPhoneNumber: 'Enter Account Phone No.',
  EnterContactPhoneNumberOne: 'Enter Contact Phone No. 1',
  EnterContactPhoneNumberTwo: 'Enter Contact Phone No. 2',
  CreateNewPassword: 'Create New Password',
  ConfirmNewPassword: 'Confirm New Password',
  UpdateAgentSuccessful: 'Update Agent Successful!',
  UpdateAgentPasswordSuccessful: 'Update Agent Password Successful!',
  MaxWithdrawAmount: 'Maximum Withdraw Amount per Transaction',
  EnterMaxWithdrawAmount: 'Enter Maximum Withdraw Amount per Transaction',

  // agent Commission
  AgentCommissionList: 'Agent Commission List',
  TargetAmount: 'Target Amount',
  CommissionPercent: 'Commission Percent',
  EnterTargetAmount: 'Enter Target Amount',
  EnterCommissionPercent: 'Enter Commission Percent',
  CreateNewCommissionSuccessful: 'Create New Commission Successful!',
  UpdateCommissionSuccessful: 'Update Commission Successful!',
  DeleteCommissionSuccessful: 'Delete Commission Successful!',
  EnterPercentage: 'Enter Percentage',
  //   Player List
  PlayerList: 'Player List',
  TotalPlayer: 'Total Player',
  ActivePlayer: 'Active Player',
  //   Player Detail
  PlayerDetail: 'Player Detail',
  WalletAmount: 'Wallet Amount',
  TotalBetAmount: 'Total Bet Amount',
  TotalWinAmount: 'Total Win Amount',
  BetHistory: 'Bet History',
  DepositHistory: 'Deposit History',
  WithdrawHistory: 'Withdraw History',
  WinningAmount: 'Winning Amount',
  BetDetails: 'Bet Details',
  DepositDetails: 'Deposit Details',
  WithdrawDetails: 'Withdraw Details',
  //   Payment Setting
  PaymentMethod: 'Payment Method',
  PaymentMethods: 'Payment Methods',
  MyAccount: 'My Account',
  AddNewPaymentMethodSuccessful: 'Add New Payment Method Successful!',
  DeletePaymentMethodSuccessful: 'Delete Payment Method Successful!',
  CreateNewPaymentMethodSuccessful: 'Create New Payment Method Successful!',
  UpdatePaymentMethodSuccessful: 'Update Payment Method Successful!',
  DeletePaymentMethodSuccessful: 'Delete Payment Method Successful!',
  AddPaymentMethod: 'Add Payment Method',
  // Request Checkout
  EnterEightDigit: 'Enter Last 6 Digits of Transaction ID',
  LastEightDigit: 'Last 6 Digits of Transaction ID',
  AgentAccountName: 'Agent Account Name',
  AgentAccountNumber: 'Agent Account Number',
  PlayerAccountName: 'Player Account Name',
  PlayerAccountNumber: 'Player Account Number',
  EnterNote: 'Enter Note',
  CheckOutType: 'Checkout Type',
  CheckoutDetails: 'Checkout Details',
  SendRequestSuccessful: 'Send Request Successful!',
  CheckoutRequestAccepted: 'Checkout Request Accepted',
  CheckoutRequestRejected: 'Checkout Request Rejected',
  AddPaymentScreenShot: 'Add Payment ScreenShot',
  subtract: 'Subtract',
  // Add New
  Refund: 'Refund',
  Reset: 'Reset',
  RefundSalesListSuccessful: 'Refund Sales List Successful!',
  ResetTimePeriodSuccessful: 'Reset Time Period Successful!',
  RefundSale: 'Refund Sale',
  RefundSaleSuccessful: 'Refund Sale Successful',
  AlreadyRefunded: 'Already Refunded',
  AlreadyWinning: 'Already Winning',
  AgentZeroError: "Agent Max Per Withdraw Can't be 0",
  MinTargetAmount: 'Min Target Amount',
  MaxTargetAmount: 'Max Target Amount',
  EnterMinTargetAmount: 'Enter Min Target Amount',
  EnterMaxTargetAmount: 'Enter Max Target Amount',
  // 4D
  FourD: '4D',
  PrizeCompensation: 'Prize Compensation',
  EnterPrizeCompensation: 'Enter Prize Compensation',
  FourDOddCard: '4D Odd Card',
  FourDTimePeriod: '4D Time Period',
  FourDCreateTimePeriod: '4D Create Time Period',
  ReleaseData: 'Release Data',
  FourDManagement: '4D Management',
  SortByNumber: 'Sort By Number',
  SortBySale: 'Sort By Sale',
  FourDSalesList: '4D Sales List',
  FourDWinnerList: '4D Winner List',
  FourDSetting: '4D Setting',
  // extra add on
  thai: 'Thai Lottery',
  singapore: 'Singapore Sweep',
};

export default en;
